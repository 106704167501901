import React, { Component } from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import BookingWrapper from "../components/booking-screen/booking-wrapper"

class BookingPage extends Component {
  render() {
    return (
      <Layout max>
        <BookingWrapper />
      </Layout>
    )
  }
}

export default BookingPage
